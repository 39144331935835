"use client";
import Image from "next/image";
import Link from "next/link";
import cookie from "@/app/assets/cookie.svg";
import { saveCookie } from "@/lib/serverActions";
import { useState } from "react";
import { BASE_URL } from "@/lib/constants";

export default function LGPDCookie() {
    const [showDiv, setShowDiv] = useState(true);
    // const cookieStore = cookies();
    // const todoosCookies = cookieStore.getAll();
    const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;

    // if (cookieStore.get('LGPDCookie')) {
    //     return <></>
    // }

    return (<>
        <button
            className="fixed bottom-0 start-0 z-50 p-4"
            onClick={() => setShowDiv(!showDiv)}
        >
            <Image src={cookie} alt="Cookie" />

        </button>
        {showDiv && <div className="fixed text-justify bottom-14 start-10  w-[20rem] bg-neutral-100 rounded-lg bg-white m-4 shadow p-4 z-50">
                <p className="font-bold underline text-sm pb-2">Preferência de Cookies</p>
                <p className="text-xs">Nosso site utiliza cookies e tecnologias semelhantes para otimizar e
                    personalizar sua experiência. Ao continuar navegando, você concorda
                    com a nossa <Link className="underline" href={`http${process.env.NODE_ENV === 'production' ? 's' : ''}://${BASE_URL}/politica-de-privacidade`}>Politica de Privacidade</Link> - <Link className="underline" href={`https://${BASE_URL}/termos-de-uso`}>Termos de Uso</Link></p>
                <div className="flex justify-center">
                    <button
                        className="bg-primary text-black rounded-md px-4 py-2 m-2"
                        onClick={() => {
                            saveCookie();
                            setShowDiv(false);
                        }}
                    >Aceitar</button>
                </div>
            </div>}
    </>)
}